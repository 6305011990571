import React, { useState, useEffect } from "react";

import "./programmes.css";
import { AccordionContent, Gallery } from "../../components";

const OurProgrammes = () => {
  return (
    <>
      <section className="ourProgrammes">
        <div className="container">
          <div className="content">
            <header className="header"></header>
            <Gallery />
            <div className="txtContent">
              <article>
                <AccordionContent />
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurProgrammes;
