import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./pro-pag.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { our_projects } from "./../../constants/data";

const ProjectsPagination = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const projectsPerPage = 12;

  const endOffset = itemOffset + projectsPerPage;
  const currentProjects = our_projects.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(our_projects.length / projectsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * projectsPerPage) % our_projects.length;
    setItemOffset(newOffset);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setItemOffset(0); // Reset pagination when search query changes
  };

  return (
    <>
      <div className="pro">
        <div className="header">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by title..."
            className="search-bar"
          />
        </div>
        <div className="cards">
          {our_projects &&
            currentProjects
              .filter((speaker) =>
                speaker.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((project) => (
                <div className="card">
                  <Link
                    to={`/our-projects/${project.id}`}
                    key={project.id}
                    className="item"
                  >
                    <div className="top">
                      <img src={project.image} alt="" />
                    </div>
                    <div className="bottom">
                      <h3>{project.title}</h3>
                      {/* <p>{project.date}</p> */}
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      </div>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        previousLabel={<IoIosArrowBack />}
        nextLabel={<IoIosArrowForward />}
      />
    </>
  );
};

export default ProjectsPagination;
