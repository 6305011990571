import React, { useState, useEffect } from "react";
import "./home.css";
import { gh, logoHeader, ng, sl, ug, zm } from "./../../assets";
import sanityClient from "../../client";
import {
  Accordion,
  AccordionContent,
  Carousel,
  Countries,
  ImageSwiper,
} from "../../components";

const Home = () => {
  const [homeContent, setHomeContent] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"]`)
      .then((data) => setHomeContent(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      <section className="hero">
        <div className="container">
          <Carousel />
        </div>
      </section>
      <section className="whoWeAre">
        <div className="container">
          <div className="col">
            <div className="content">
              <h3>Who We Are</h3>
              <h2>
                Some of Africa’s poorest countries have had their problems
                aggravated by war, famine and other natural disasters creating
                an overwhelming need for external assistance in order to rise
                above their plight.
              </h2>
              <p>
                The devastating effect on the socio-economic climates of the
                countries concerned is self evident & the spill-over effects
                into neighbouring countries and the African continent as a whole
                is nothing short of catastrophic especially for the children
                born into the middle of it all...
              </p>
              <div className="text-center text-lg-start">
                <a href="/about-us" className="btn-read-more">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="imhWrapper">
              <img src={logoHeader} alt="logo" />
            </div>
          </div>
        </div>
      </section>
      <section className="imageSlide">
        <ImageSwiper />
      </section>
      <section className="values">
        <div className="container">
          <div className="col">
            <div class="box">
              <h3>
                OUR
                <br />
                MISSION
              </h3>
              {/* <p>{homeContent?.mission}</p> */}
              <p>
                Relief of poverty of children in Africa from the effects of war,
                disease and disability. Providing or assisting in the provision
                of equipment, facilities and services that will initially meet
                their basic essential needs in a bid to break the cycle of
                poverty.
              </p>
            </div>
          </div>
          <div className="col">
            <div class="box">
              <h3>
                OUR
                <br />
                OBJECTIVES
              </h3>
              {/* <p>{homeContent?.objectives}</p> */}
              <p>
                To break the oppressive cycle of children’s poverty in African
                Countries <br />
                To up-skill our target group through the provision of training
                for employment, employment advice, information, assistance,
                counselling, advocacy, translating and representation services.{" "}
                <br />
                To establish sponsorships for children in Africa <br />
                To promote mass literacy, bespoke training and education amongst
                the target group of children <br />
                To provide Health, Housing, Social and Welfare assistance to,
                children and vulnerable young people in Africa & provide follow
                up support as appropriate. <br />
                To undertake research and disseminate services in furtherance of
                our objectives <br />
                To promote cultural and information exchange at home and abroad.
                Comments are closed.
              </p>
            </div>
          </div>
          <div className="col">
            <div class="box">
              <h3>
                OUR
                <br />
                VISION
              </h3>
              {/* <p>{homeContent?.vision}</p> */}
              <p>
                Today’s children in Africa will be the leaders of tomorrow and
                as such we seek to improve their quality of life and provide
                some of the the essential facilities that will bring hope and
                new life as they develop for the future which in turn will
                facilitate their empowering the generation that follows in a
                continuous cycle of improvement.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="core-values">
        <div className="container">
          <header class="header">
            <h1>our core values</h1>
          </header>
          <div className="txtContent">
            {/* <p>{homeContent?.corevalues}</p> */}
            <p>
              Children should be loved and cared for so that their spirit of
              humanity will be nurtured & allowed to bloom. Children matter and
              we acknowledge that every individual or groups who share in our
              activities help to shape and mould the life and purpose of
              children in Africa. Children everywhere should be entitled to the
              same facilities and resources such as education, health care and
              the basic necessities of food, clothing and shelter, irrespective
              of their location, race, gender, religion or culture so as to
              better prepare them for their future.
            </p>
          </div>
          {/* <div className="btnWrapper">
            <a href="/core-values" class="btn-read-more grey-color">
              <span>See More</span>
              <i class="bi bi-arrow-right"></i>
            </a>
          </div> */}
        </div>
      </section>
      <section className="programmes">
        <div className="container">
          <header class="header">
            <h1>our programmes</h1>
          </header>
          <div className="txtContent">
            <p>
              Save Children in Africa in Need (SCAN) is a grass root
              organisation with on-going projects supporting education, health
              and social initiatives in rural villages in five African countries
              (Ghana, Sierra Leone, Nigeria, Zimbabwe and Uganda). Our main
              purpose and mission is to bring relief from poverty to children in
              Africa who have suffered from the effects of war, disease and
              disability, by assisting to provide clothes, equipment, facilities
              and services that will go towards meeting some of their
              fundamental, basic & urgent needs.SCAN wishes to bring new life
              and hope to such children, by providing them with the very basic
              necessities of life to improve their chances of surviving
              childhood. SCAN currently runs four programmes:
            </p>
          </div>
          <div className="dropDowns">{/* <AccordionContent /> */}</div>

          <div className="btnHolder">
            <a href="our-programmes" class="btn-read-more">
              <span>See More</span>
              <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      <section className="devOps">
        <div className="container">
          <header class="header">
            <h1>Delopmental Opportunities</h1>
          </header>
          <div className="cards">
            <div className="card">
              <div class="service-box yellow">
                {/* <p>{homeContent?.devopp1}</p> */}
                <p>
                  To encourage children to develop their potential, contribute
                  to their upbringing and help to mould their lives. This will
                  be done through training and development and educational
                  programmes in their respective countries.
                </p>
              </div>
            </div>
            <div className="card">
              <div class="service-box green">
                {/* <p>{homeContent?.devopp2}</p> */}
                <p>
                  To ensure that our services and facilities are made available
                  to as many children in Africa regardless of their location,
                  race, gender, religion, or culture.
                </p>
              </div>
            </div>
            <div className="card">
              <div class="service-box green">
                {/* <p>{homeContent?.devopp3}</p> */}
                <p>
                  To provide and encourage the interaction and participation of
                  children with their counterparts in various African countries.
                  This will be in the form of sporting activities, exhibitions
                  and exchange programmes.
                </p>
              </div>
            </div>
            <div className="card">
              <div class="service-box grey">
                {/* <p>{homeContent?.devopp4}</p> */}
                <p>
                  To be aware of and build upon the expectations and perceptions
                  of children and to respond to them appropriately. We recognise
                  and value each child, and acknowledge the dignity and
                  uniqueness of each.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="projects">
        <div className="container">
          <header class="header">
            <h1>Our Projects</h1>
          </header>
          <div className="textContent">
            {/* <p>{homeContent?.projects}</p> */}
            <p>
              Save Children in Africa in Need (SCAN) is a grass root
              organisation with on-going projects supporting education, health
              and social initiatives in rural villages in five African countries
              (Ghana, Sierra Leone, Nigeria, Zimbabwe and Uganda). Our main
              purpose and mission is to bring relief from poverty to children in
              Africa who have suffered from the effects of war, disease and
              disability, by assisting to provide clothes, equipment, facilities
              and services that will go towards meeting some of their
              fundamental, basic & urgent needs.SCAN wishes to bring new life
              and hope to such children, by providing them with the very basic
              necessities of life to improve their chances of surviving
              childhood.
            </p>
          </div>
          <Countries />
          <div className="btnHolder">
            <a href="/our-projects" class="btn-read-more grey-color">
              <span>See More</span>
              <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
