import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AOS from "aos";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./carousel.css";

// import required modules
import { Autoplay, Parallax, Pagination, Navigation } from "swiper";

const Carousel = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });
  return (
    <>
      <Swiper
        // style={{
        //   "--swiper-navigation-color": "#133860",
        //   "--swiper-pagination-color": "#133860",
        // }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Parallax, Pagination, Navigation]}
        className="carouselSwiper"
      >
        {/* <div
          slot="container-start"
          className="parallax-bg slide-1"
          data-swiper-parallax="-23%"
        /> */}
        <SwiperSlide>
          <div
            slot="container-start"
            className="parallax-bg slide-1"
            data-swiper-parallax="-23%"
          />
          <div
            className="txtOverlay"
            data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="600"
          >
            <div className="title" data-swiper-parallax="-300">
              Schools Awareness Programme
            </div>

            <div className="text" data-swiper-parallax="-100">
              <p>
                The whole idea is to raise the awareness of the plight of
                schools in Africa and facilitate activities, through
                participants, to fund projects in these schools.
                <br />
                <br />
                Providing funds to educate children in Africa, especially girls,
                is one of our core programmes.  We believe that Children
                everywhere should be entitled to the same facilities and
                resources such as health care, education and the basic
                necessities of food, clothing and shelter, irrespective of their
                location, race, gender, religion or culture so as to better
                prepare them for their future duties and responsibilities as
                adult citizens of their respective countries.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            slot="container-start"
            className="parallax-bg slide-2"
            data-swiper-parallax="-23%"
          />
          <div className="txtOverlay">
            <div className="title" data-swiper-parallax="-300">
              Scholarship Programme
            </div>

            <div className="text" data-swiper-parallax="-100">
              <p>
                The Scholarship Programme, set up to provide financial support
                for children in need in Africa, will initially support children
                in the FIVE African countries where SCAN has ongoing projects:
                namely Ghana, Sierra Leone, Nigeria, Zimbabwe and Uganda.
                <br />
                <br />
                The overall objective of the Scholarship Programme is to provide
                financial support (scholarships) for needy children in Africa.
                Comments are closed.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            slot="container-start"
            className="parallax-bg slide-3"
            data-swiper-parallax="-23%"
          />
          <div className="txtOverlay">
            <div className="title" data-swiper-parallax="-300">
              Schools Exchange Programme
            </div>

            <div className="text" data-swiper-parallax="-100">
              <p>
                The Schools Exchange Programme provides intercultural learning
                experiences that bring global cultures together by helping
                students connect, transforming the lives of participants. The
                value of Schools Exchange Programme is an experience of a
                lifetime. The mixing of cultures and backgrounds provides young
                people with an unforgettable awareness of and lifelong
                appreciation for each other. The Programme gives teenagers from
                the UK and African countries, the opportunity to broaden their
                horizons by living in a country abroad for 3 to 4 weeks.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
