export const navigationLinks = [
  {
    id: "1",
    name: "home",
    url: "/",
  },
  {
    id: "2",
    name: "about us",
    url: "/about-us",
  },
  {
    id: "3",
    name: "our programmes",
    url: "/our-programmes",
  },
  {
    id: "4",
    name: "our projects",
    url: "/our-projects",
  },

  // {
  //   id: "5",
  //   name: "events",
  //   url: "/events",
  // },
  {
    id: "6",
    name: "contact us",
    url: "/contact",
  },
];

export const navigation = [
  {
    id: "1",
    name: "FAQs",
    url: "/faq",
  },

  {
    id: "2",
    name: "home",
    url: "/",
  },
  {
    id: "3",
    name: "portfolio",
    url: "/portfolio",
  },
  {
    id: "4",
    name: "our story",
    url: "/our-story",
  },
  {
    id: "5",
    name: "services",
    url: "/services",
  },
];

export const products = [
  {
    id: "1",
    name: "ghana",
    url: "/our-projects",
  },

  {
    id: "2",
    name: "nigeria",
    url: "/our-projects",
  },
  {
    id: "3",
    name: "uganda",
    url: "/our-projects",
  },
  {
    id: "4",
    name: "zimbabwe",
    url: "/our-projects",
  },
  {
    id: "5",
    name: "sierra leone",
    url: "/our-projects",
  },
];
