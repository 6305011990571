import React, { useState, useEffect } from "react";
import "./countries.css";
import { gh, logoHeader, ng, sl, ug, zm } from "./../../assets";
import sanityClient from "../../client";

const Countries = () => {
  // const [countryContent, setCountryContent] = useState([]);

  // useEffect(() => {
  //   sanityClient
  //     .fetch(`*[_type == "country"]{..., image->}`)
  //     .then((data) => setCountryContent(data[0]))
  //     .catch(console.error);
  // }, []);

  // useEffect(() => {
  //   console.log("country content: ", countryContent);
  // }, [countryContent]);

  return (
    <>
      <div className="countries">
        <div className="country">
          <div className="flag">
            <img src={sl} alt="sierra leone" />
          </div>
          <div className="name">
            <p>sierra leone</p>
          </div>
        </div>

        <div className="country">
          <div className="flag">
            <img src={gh} alt="ghana" />
          </div>
          <div className="name">
            <p>ghana</p>
          </div>
        </div>
        <div className="country">
          <div className="flag">
            <img src={ng} alt="nigeria" />
          </div>
          <div className="name">
            <p>nigeria</p>
          </div>
        </div>
        <div className="country">
          <div className="flag">
            <img src={ug} alt="uganda" />
          </div>
          <div className="name">
            <p>uganda</p>
          </div>
        </div>
        <div className="country">
          <div className="flag">
            <img src={zm} alt="zimbabwe" />
          </div>
          <div className="name">
            <p>zimbabwe</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Countries;
