import React, { useRef, useState } from "react";
import { ScrollToTop } from "./../../components";
import emailjs from "@emailjs/browser";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const initialState = {
    name: "",
    email: "",
    message: "",
  };

  const [{ name, email, message }, setState] = useState(initialState);

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    emailjs
      .sendForm("service", "template", form.current, "1A1gH7OkaK_n2sz-s")
      .then(
        (result) => {
          console.log(result.text, result.status);
          clearState();
          setStatusMessage("Message sent! We'll get back to you shortly.");
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            `${error.text} Couldn't send. Check your network connection`
          );
        }
      );
    e.target.reset();
  };
  return (
    <>
      <section className="contactPageSection">
        <div className="container">
          <div className="contactPageHeader">
            <h1>Contact Us</h1>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.816327842112!2d-0.01753592346654301!3d51.47988562180702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876028f1a6a6a9b%3A0xa20c34b893d15ccd!2sHaddo%20St%2C%20London%20SE10%209SF%2C%20UK!5e0!3m2!1sen!2sgh!4v1685111220600!5m2!1sen!2sgh"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Address"
            ></iframe>
          </div>
          <div className="addressForm">
            <div className="address">
              {/* <small>
                Lorem, ipsum dolor.
                <br />
                Lorem, ipsum dolor.
                <br />
                Lorem, ipsum dolor.
              </small> */}
              <br /> <br />
              <small>
                55 Haddo House <br /> Haddo Street <br />
                Greenwich
                <br /> London <br />
                SE10 9SF <br />
                Email: info@savechildreninafricainneed.org.uk
              </small>
            </div>
            <div className="contactForm">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Full Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Your Message"
                  required
                ></textarea>
                <p className="alertMsg">{statusMessage}</p>
                <button type="submit" className="btn-read-more">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="redWave" />
      </section>
      <ScrollToTop />
    </>
  );
};

export default Contact;
