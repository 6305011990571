import React from "react";
import { Link } from "react-router-dom";
import {
  navigationLinks,
  navigation,
  products,
  services,
} from "./../../constants/links";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaSistrix } from "react-icons/fa";

import { Logo2 } from "./../../assets";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="top">
          <div className="container">
            <div className="row">
              <div className="col summary">
                <div className="logo">
                  <img src={Logo2} alt="logo" />
                </div>
                <div className="details">
                  <article>
                    Some of Africa’s poorest countries have had their problems
                    aggravated by war, famine and other natural disasters
                    creating an overwhelming need for external assistance in
                    order to rise above their plight.
                  </article>
                </div>
                <div className="socials">
                  <Link
                    target="_blank"
                    to={"https://instagram.com"}
                    className="icons"
                  >
                    <BsInstagram className="icon" />
                  </Link>
                  <Link
                    target="_blank"
                    to={"https://www.facebook.com/profile.php?"}
                    className="icons"
                  >
                    <BsFacebook className="icon" />
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="menu">
                  <div className="header">
                    <h3>menu</h3>
                  </div>
                  {navigationLinks.map((links) => {
                    return (
                      <Link className="menu_item" key={links.id} to={links.url}>
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="col">
                <div className="menu">
                  <div className="header">
                    <h3>our projects</h3>
                  </div>
                  {products.map((links) => {
                    return (
                      <Link className="menu_item" key={links.id} to={links.url}>
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="col">
                <div className="header">
                  <h3>contact us</h3>
                </div>
                <div className="address">
                  55 Haddo House <br /> Haddo Street <br />
                  Greenwich
                  <br /> London <br />
                  SE10 9SF
                </div>
                <div className="contacts">
                  <div className="con">
                    <div className="bold">Phone:</div>
                    <div className="regular">+44 7985279263</div>
                  </div>
                  <div className="con">
                    <div className="bold">email:</div>
                    <div className="regular">info@scancharity.org.uk</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="copyright">
              <div className="copy-name">
                &copy;
                <script>document.write(new Date().getFullYear());</script>
                {""}{" "}
                <Link to={"./"}>
                  {" "}
                  Copyright <b>Scan</b> . All Rights Reserved
                </Link>{" "}
                <br />
                <small>Designed & developed by </small> |
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
