import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import {
  AboutUs,
  Blog,
  Contact,
  Home,
  NotFoundPage,
  OurProgrammes,
  OurProjects,
  ProjectDetail,
} from "./pages";

import Layout from "./Layouts/Layout";
import ProjectDetailLayout from "./Layouts/ProjectDetailLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index exact element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/our-programmes" element={<OurProgrammes />} />
      <Route path="/our-projects" element={<OurProjects />} />

      <Route path="/our-projects" element={<ProjectDetailLayout />}>
        <Route path="/our-projects/:eventId" element={<ProjectDetail />} />
      </Route>

      {/* <Route path="/sermon" element={<SlideContent />}>
        <Route path="/sermon/:id" element={<Sermon />} />
      </Route> */}

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
