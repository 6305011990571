// import client from "@sanity/client";

// export default client({
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   dataset: "production",
//   useCdn: true,
//   apiVersion: "2023-06-28",
// });

import sanityClient from "@sanity/client";
export default sanityClient({
  projectId: process.env.REACT_APP_PROJECT_ID,
  dataset: "production",
  useCdn: true,
  apiVersion: "2023-06-28",
});

// import { createClient } from "@sanity/client";

// const client = createClient({
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   dataset: "production",
//   useCdn: true,
//   apiVersion: "2023-28-06",
// });
