import React from "react";
import "./gallery.css";
import {
  abandze1,
  blind3,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  schAware1,
  schX3,
} from "../../assets";

const Gallery = () => {
  return (
    <>
      <div className="img__gallery">
        <div className="imgItem i-1">
          <img src={schAware1} alt="" />
        </div>
        <div className="imgItem i-2">
          <img src={img7} alt="" />
        </div>
        <div className="imgItem i-3">
          <img src={blind3} alt="" />
        </div>
        <div className="imgItem i-4">
          <img src={abandze1} alt="" />
        </div>
        <div className="imgItem i-5">
          <img src={schX3} alt="" />
        </div>
        <div className="imgItem i-5">
          <img src={img5} alt="" />
        </div>
      </div>
    </>
  );
};

export default Gallery;
