import React, { useState, useEffect } from "react";
import { Accordion } from "../index";
import "./accordion_content.css";

const AccordionContent = () => {
  const [programsContent, setProgramsContent] = useState([]);

  useEffect(() => {
    console.log("programs Content: ", programsContent);
  }, [programsContent]);

  return (
    <>
      <section id="service_dealings">
        <div className="service_dealings_wrapper">
          <div className="top">
            <p
              className="sub_head"
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
            ></p>
          </div>
          <Accordion title={"Schools Exchange Programme"}>
            <article>
              <p>
                Through the Schools Exchange Programme, participating schools in
                the UK and African countries benefits will include:
              </p>
              <ul>
                <li>
                  Mutual appreciation and study of diversity – cultural
                  difference and history of partner school’s local environment,
                  staff development, opportunities for school improvement and
                  enhancing curriculum across the board.
                </li>{" "}
                <li>
                  Provides a platform to learn about real people in their own
                  community, which leads to better understanding and knowledge
                  of global communities.
                </li>
                <li>
                  Provides an opportunity for regularly working with others from
                  different ethnic, cultural, religious and socio-economic
                  backgrounds.
                </li>
                <li>
                  Could be a platform for developing strong ethos and culture of
                  mutual respect and support development of effective planning
                  and teaching curriculum projects, where teachers and students
                  reflect on identities and cultures of communities.
                </li>
                <li>
                  Better understanding of the organisation and structure of the
                  partner schools in Africa
                </li>
              </ul>
            </article>
            <div>
              <br />

              <article>
                <h4>Background Justification</h4>
                <p>
                  The Schools Exchange Programme provides intercultural learning
                  experiences that bring global cultures together by helping
                  students connect, transforming the lives of participants. The
                  value of Schools Exchange Programme is an experience of a
                  lifetime. The mixing of cultures and backgrounds provides
                  young people with an unforgettable awareness of and lifelong
                  appreciation for each other. The Programme gives teenagers
                  from the UK and African countries, the opportunity to broaden
                  their horizons by living in a country abroad for 3 to 4 weeks.
                  The programme enables participants to learn more about other
                  cultures and way of life first hand. Exchange students will
                  also experience new traditions and learn the way their peers
                  live day to day.
                </p>
                <p>
                  Exchange programme give participants an all-access pass to
                  discover and explore a new culture and share their
                  experiences. Our exchange programme draw students from African
                  countries like, Ghana, Nigeria, Sierra Leone, Uganda and
                  Zimbabwe who want to get to know and learn from their peers in
                  the United Kingdom. This will be reciprocated by UK students
                  visiting the same African countries. Participants live with a
                  host family while attending school, gaining a fresh
                  perspective on a foreign culture. By experiencing new cultures
                  as outsiders, SCAN participants also gain a deeper
                  understanding of themselves and their home cultures.
                </p>
                <p>
                  SCAN students live with carefully selected host families and
                  attend local secondary schools. By living with a native host
                  family, SCAN students become integrated into the way of life
                  and culture of the United Kingdom. Each student has a local
                  coordinator who monitors and provides support 24 hours, 7 days
                  a week. Participants are carefully matched, and volunteers are
                  always on hand to make sure the host families and students
                  have the support and tools needed for a positive experience.
                  In order to ensure the safety and well-being of participants,
                  SCAN carefully pre-screens host families to make sure they
                  will provide an appropriate and welcoming environment.
                </p>{" "}
                <p>
                  SCAN Volunteers run additional orientation sessions for
                  participants and host families at different stages of the
                  exchange experience. The first meetings to prepare
                  participants for their programme happen before the
                  participants leave their home countries. Additional
                  orientation sessions take place upon arrival in the UK, during
                  the exchange program, and even after the participants return
                  home.
                </p>
                <p>
                  Host schools give SCAN participants the chance to interact
                  with their peers in a social setting. They provide a place
                  where students can share, learn and reflect on their
                  experiences. Teachers and other students also benefit from the
                  opportunity to bond with someone from a different culture.
                  Exchange students bring fresh perspectives and valuable
                  insights, which can help their hosting schools share
                  intercultural skills and knowledge with all students.
                </p>
              </article>
              <article>
                <h4>Benefits</h4>
                <p>
                  <b>Cultural Awareness: </b>Culture is the values, beliefs,
                  assumptions and ideas that a people or a society hold in
                  common. Being able to develop cultural awareness begins by
                  understanding what culture is.{" "}
                </p>
                <p>
                  <b>School experience:</b>There is no better way to come to
                  understand people of your own age group than by going to
                  school with them. High School student exchange programs give
                  you the opportunity to truly be a part of the host country and
                  to understand your peers.
                </p>
                <p>
                  <b>Self-confidence:</b>Students learn to make more decisions
                  on their own and become more confident and self-assured.
                </p>{" "}
                <p>
                  <b>Becoming a global citizen:</b>Students become more adapt in
                  not only learning about another culture but in understanding
                  their own.
                </p>{" "}
                <p>
                  <b>Maturity:</b>Students see the world in a different way,
                  become more patient, flexible and learn to appreciate
                  different lifestyles.
                </p>
                <p>
                  <b>New Friendships</b>
                  <br />
                  During the experience abroad, participants often build close
                  attachments with their host families through daily interaction
                  in the household. Participants also form deep and meaningful
                  connections with other SCAN participants, who they meet at
                  orientation sessions and activities organized by SCAN in the
                  host country, often for a lifetime. One reason for this may be
                  because of the huge interest you find in one another because
                  you come from different cultures.
                </p>
                <p>
                  <b>Personal Growth</b>
                  <br />
                  Through immersion in a foreign culture, SCAN participants
                  expand their perspectives and learn more about themselves in
                  the process. Exchange programs build maturity as participants
                  become more self-reliant and confident in their own abilities.
                  By experiencing another culture as an outsider, they gain a
                  deeper understanding of the world and take steps towards
                  becoming responsible global citizens. Participants also learn
                  to be an adventurer, a traveler, and an explorer.
                </p>
                <p>
                  <b>Lasting Effects</b>
                  <br />
                  SCAN Participants benefit from the experience for the rest of
                  their lives. On their return to their home countries,
                  participants show higher levels of intercultural sensitivity
                </p>
                <p>
                  <b>Impact on the Community</b>
                  <br />
                  Besides the direct benefits for participants, exchange
                  programs have a positive and lasting impact on the larger
                  community. SCAN Participants enrich the educational experience
                  of fellow students, both in the host country and in their own
                  schools. The school-based exchange programs offered by SCAN
                  give students the chance to interact with someone from another
                  culture. In the same manner, participants are able to
                  contribute to their schools' curricula upon their return,
                  sharing what they have learned with their peers.
                </p>
              </article>
            </div>
          </Accordion>
          <Accordion title={"Schools Awareness Programme "}>
            <article>
              The ‘Schools Awareness Programme’ is a programme in which
              participants (schools, organisations, groups and individuals in
              the UK) choose a project from a selected list of needy projects in
              Africa, determine their time scale and method of raising funds and
              raise the funds for the chosen projects. SCAN implements and
              monitors the project, in close consultation with the UK
              participants. The whole idea is to raise the awareness of the
              plight of schools in Africa and facilitate activities, through
              participants, to fund projects in these schools in Africa.{" "}
              <p>
                Through the Schools Awareness Programme, participating schools
                in the U.K. benefits will include:
              </p>{" "}
              <ul>
                <li>
                  Hands-on project development and implementation experience to
                  interested students in participating schools in London.
                </li>
                <li>
                  Students will be able to directly contribute towards
                  charities, and to enable them to address the problems of
                  targeted children in Africa.
                </li>
                <li>
                  Participants will develop self-esteem, knowing that they have
                  contributed to a specific schools project in Africa and that
                  they can identify with the project, thus enhancing their
                  citizenship programme in school.
                </li>
              </ul>
            </article>
          </Accordion>
          <Accordion title={"Skills Development Programme (Skills for Life)"}>
            <article>
              <p>
                This programme compliments the Schools Awareness Programme. The
                aim of this programme is to enable the beneficiaries in Africa
                (including adult children and young people with Disabilities) to
                have sustainable income for themselves and their families,
                thereby becoming independent citizens in their respective
                countries.{" "}
              </p>{" "}
              <p>
                Talents and potentials of these adult children and young people,
                in Africa, are identified and developed into life-long skills.
                Through the Schools Awareness Programme, SCAN provides these
                adult children and young people with the resources (tools,
                equipments and raw materials) and facilities needed to develop
                these skills which include knitting, handicraft, and sports.
              </p>{" "}
              <p>
                To pilot this programme in 2007, SCAN provided Paul’s School for
                the Blind in Bo Town, Sierra Leone, knitting equipment and
                thread which the school used to teach blind students to knit
                African fabric. These fabrics are used to produce clothes for
                men and women. To date, the programme is self sustained as the
                proceeds are used to provide the thread and equipments needed to
                run the programme in the school.
              </p>
            </article>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default AccordionContent;
