import React from "react";
import "./projects.css";
import { ProjectsPagination } from "../../components";

const OurProjects = () => {
  return (
    <>
      <section className="ourProjects">
        <div className="container">
          <header class="header">
            <h1>Our Projects</h1>
          </header>
          <div className="proSingle">
            <ProjectsPagination />
          </div>
        </div>
      </section>
    </>
  );
};

export default OurProjects;
