import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./project-detail.css";
import { events } from "./../../constants/data";
import { ProjectGallery } from "../../components";

const ProjectDetail = () => {
  const { eventId } = useParams();
  const ourProject = events.find((project) => project.id === eventId);
  const [detailContent, setDetailContent] = useState("");

  useEffect(() => {
    if (ourProject) {
      if (ourProject.detailFile) {
        // Fetch content from the external file
        fetch(ourProject.detailFile)
          .then((response) => response.text())
          .then((data) => setDetailContent(data))
          .catch((error) =>
            console.error("Error fetching detail file:", error)
          );
      } else if (ourProject.detail) {
        // Use the detail property directly
        setDetailContent(ourProject.detail);
      }
    }
  }, [ourProject]);

  return (
    <>
      <section className="projectDetails">
        <div className="container">
          <header className="header">
            <h1>{ourProject.title}</h1>
          </header>
          {/* {ourProject.detail ? (
            <article dangerouslySetInnerHTML={{ __html: ourProject.detail }} />
          ) : null} */}

          {detailContent ? (
            <article dangerouslySetInnerHTML={{ __html: detailContent }} />
          ) : null}

          {ourProject.photo ? (
            <ProjectGallery galleryImages={ourProject.photo} />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default ProjectDetail;
