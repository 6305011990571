import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  abandze1,
  abandze2,
  abandze3,
  abandze4,
  schX1,
  schX2,
  schX3,
  schX4,
  schX5,
  blind1,
  blind2,
  blind3,
  blind4,
  schAware1,
  schAware2,
  schAware3,
  schAware4,
  schAware5,
  schAware6,
  aban24_2,
  aban24_1,
  aban24_3,
  aban24_4,
  paul24_3,
  paul24_1,
  paul24_2,
  paul24_4,
  summer24_4,
  summer24_1,
  summer24_2,
  summer24_3,
  sports24_5,
  sports24_1,
  sports24_2,
  sports24_3,
  sports24_4,
  toilet24_7,
  toilet24_1,
  toilet24_2,
  toilet24_3,
  toilet24_4,
  toilet24_5,
  toilet24_6,
  water24_3,
  water24_1,
  water24_2,
  water24_4,
  water24_5,
  water24_6,
  Logo,
  logoHeader,
} from "../assets";

export const faqs = [
  {
    id: 1,
    title: "How can I apply?",
    details:
      "Group application: \n \n If you are an institution or organization that needs to apply as a group, the group leaders will complete the form via the website. They will indicate the institution, number of people, and proposed dates for the immersion program. We will get back to you. \n \n Individual application: \n \n Please complete the form via the website, and we will reply.",
    sectionID: "application",
  },
  {
    id: 2,
    title: "What are the eligibility requirements?",
    details:
      "•	Must be actively registered in a College or University \n •	Must be on an excellent academic standard (2.5 GPA or above) \n •	Disciplinary record (Not on probation) \n •	Valid passpor ",
    sectionID: "eligibility",
  },
  {
    id: 3,
    title: " What is the duration?",
    details:
      "The immersion program is over ten days —eleven hours direct flight from New York to Accra, Ghana.",
    sectionID: "trip",
  },
  {
    id: 4,
    title: "What cultural experiences can I expect?",
    details:
      "Participants will start the community entry by meeting the Paramount Chief of Kumbungu and his elders. This approach will serve as an introduction to the culture of the community members, enhancing their integration and acceptance into the community. They will also take a tour of the city and see the architecture, farming system, and the many livelihoods of the people. Participants will visit historical sites such as the cape coast, the Elmina Castle, Mole national park, Akosombo Dam, Independence Square, the ministries, the Flagstaff house, Aburi Gardens, and Kwame Nkrumah Mausoleum.",
    sectionID: "trip",
  },
  {
    id: 5,
    title: "Safety, security, and health?",
    details:
      "The U.S. Department of State labeled Ghana as a level 2 category. Participants will live in a secured building and locating with a security guard present at all times. Participants will always be with the group or staff member. They will be traveling as a group across the country. \n \n ARRANGEMENT has been made with various hospitals based on location to help with emergencies. The amount includes visas, flight tickets, traveling, site seeing, security, room, and board. The amount may change based on the time of travel",
    sectionID: "safty",
  },
];

export const testimonies = [
  {
    id: "0",
    text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi eligendi officiis aliquam quae quaerat repellat, delenit consequuntur nisi praesentium debitis?",
    person: " ",
  },
  {
    id: "1",
    text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi eligendi officiis aliquam quae quaerat repellat, delenit consequuntur nisi praesentium debitis? ",
    person:
      "~ Kennedy Messado, a sophomore psychology major from Kingston, Jamaica",
  },
  {
    id: "2",
    text: "“Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi eligendi officiis aliquam quae quaerat repellat, delenit consequuntur nisi praesentium debitis?",
    person: "~ Jasmin Blanco-Zapata, a junior from Westchester, New York",
  },
  {
    id: "3",
    text: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi eligendi officiis aliquam quae quaerat repellat, delenit consequuntur nisi praesentium debitis?",
    person: "~ Jasmin",
  },
  {
    id: "4",
    text: "“Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi eligendi officiis aliquam quae quaerat repellat, delenit consequuntur nisi praesentium debitis?”",
    person: " ",
  },
];
// const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const events = [
  {
    id: "1",
    cart: "Message",
    image: img1,
    title: "africana night ",
    date: " April 9, 2015",
    detail: "",
    photo: [
      { src: img5 },
      { src: img6 },
      { src: img7 },
      { src: img8 },
      { src: img3 },
    ],
  },
  {
    id: "2",
    cart: "Message",
    image: img2,
    title: "sponsored walk 2015 ",
    date: " April 9, 2015",
    detail: "",
    photo: [
      { src: img5 },
      { src: img6 },
      { src: img7 },
      { src: img8 },
      { src: img3 },
    ],
  },
  {
    id: "3",
    cart: "Message",
    image: img3,
    title: "cycle challenge ",
    date: " April 9, 2015",
    detail: `<p>The cycling used the Thames Cycle Route 1 from Woolwich Waterfront to Erith Pier Stretch where all cyclists were invited to join in a bring your own picnic event at The Riverside Garden just by the finishing line. </p><p>The Cycle Challenge started at 10:00am for a 10:30 set off taking 90 minutes, maximum. The route, by the river was truly scenic.   Funds raise went to SCAN’s schools’ projects in Africa.</p>`,
    photo: [img5, img6, img7, img8, img3],
  },
  {
    id: "4",
    cart: "Message",
    image: img4,
    title: "dinner and dance ",
    date: " November 21, 2015",
    detail: "",
    photo: [
      { src: img5 },
      { src: img6 },
      { src: img7 },
      { src: img8 },
      { src: img3 },
    ],
  },

  {
    id: "5",
    cart: "Message",
    image: blind1,
    title: "donation of writing frames and musical instruments to pauls ",
    date: " Month day, year",
    detail: "",
    photo: [
      { src: blind1 },
      { src: blind2 },
      { src: blind3 },
      { src: blind4 },
      { src: blind2 },
    ],
  },
  // {
  //   id: "6",
  //   cart: "Message",
  //   image: abandze1,
  //   title: "abandze school in ghana ",
  //   date: " Month day, year",
  //   detail: ``,
  //   photo: [
  //     { src: abandze1 },
  //     { src: abandze2 },
  //     { src: abandze3 },
  //     { src: abandze4 },
  //     { src: abandze2 },
  //   ],
  // },

  {
    id: "7",
    cart: "Message",
    image: schX1,
    title: "schools exchange programme Theresa Amara's visit to uk ",
    date: " Month day, year",
    detail: `<h4>My three weeks experience at Christ the King Sixth Form College from 7th November 2011 to 28th November 2011
By Theresa Amara
</h4><p>I want to thank Save Children in Africa in Need (SCAN) and Christ the King Sixth Form College for making it possible for me to visit the United Kingdom on a three weeks’ placement, from 7th November 2011 to 28th November 2011.</p> <p>I also want to thank the Circuit Superintendent Minister of Deptford Methodist Church and the entire membership of the Church for their warm welcome and for making me feel at home, throughout my stay in the UK. 
I am happy that I was able to present a clip portraying skills and craftsmanship in Sierra Leone such as farming, painting, weaving, etc. It was a wonderful experience, sharing with the children and young people at Deptford Methodist Church.</p> <p>I am particularly thankful because the Exchange Programme changed my mindset and my life in a positive way and I was able to develop a number of transferable skills which I am still using, to date.
</p><p>On my return to Sierra Leone, SCAN started an IT programme in Bo Town (my home town) and I was given the opportunity to share the IT skills I had developed at Christ 
The King with children and young adults in Bo Town. While the programme was for both boys and girls, I was particularly interested in girls as they were marginalised and very vulnerable in my community. </p><p>Through the Schools’ Exchange Programme, I was able to develop my computer skills. Before my arrival in the UK, I had never used a laptop.</p><p>Coming to the UK enabled me to learn computer skills like Word and Excel. I also developed Swimming skill and Public Speaking Skill. 
The places I visited include Buckingham Palace, Trafalgar Square, Piccadilly Circus, London Zoo, Oxford University Campus, etc.</p><p>I also visited the Sierra Leone High Commission where I met the Deputy High Commissioner, Head of Chancery and other senior officials at the High Commission.
On my return to Sierra Leone, I was able to apply my IT skills in my studies at the University of Sierra Leone to complete my assignments, carry out research for my dissertation, etc.</p><p>The programme motivated me to be more charitable. In my childhood days, I worked as a helper at Paul’s School for the Blind, After I graduated, I worked as a volunteer at ‘Teach for Sierra Leone (TFSL). My reason for working at TFSL was to give a helping hand to the Less privileged children. I have completed my Bachelor’s degree and I now look forward to my Postgraduate programme while I continue to give voluntary service to “Teach for Sierra Leone.</p>`,
    photo: [
      { src: schX1 },
      { src: schX2 },
      { src: schX3 },
      { src: schX4 },
      { src: schX5 },
    ],
  },
  {
    id: "8",
    cart: "Message",
    image: schAware1,
    title: "schools awareness programme  ",
    date: " Month day, year",
    detail:
      "Choose a project from a selected list of need projects in Africa,Determine their time scale and method of raising funds, Raise the funds for the chosen project. SCAN implements and monitors theproject, in close consultation with the participant. The whole idea is to raise the awareness of the plight of schools in Africa and facilitate activities, through participants, to fund projects in these schools. \n \n This programme has been extended to interested schools, churches,businesses and individuals.",
    photo: [
      { src: schAware2 },
      { src: schAware3 },
      { src: schAware5 },
      { src: schAware6 },
      { src: schAware4 },
    ],
  },

  {
    id: "9",
    cart: "Message",
    image: aban24_2,
    title:
      "The Abandze Methodist Basic Schools, Ghana. Proposal for funding under SCAN’s School Support Programme",
    date: " October 2023",
    // detailFile: "./templates/abandzeProjectDetail.html",
    detail: `<p>In February 2024, SCAN provided the Abandze Methodist Basic Schools in Ghana with classroom furniture (desks and chairs) and 2 projectors to improve the quality of education in the school.</p><p>Abandze, is a rural town in the central region of Ghana. The main occupation is fishing, sewing, tailoring and food making. Ghana is the second-most populous country in West Africa, after Nigeria; Accra – the capital and is the largest city. </p><p>The Abandze Methodist Basic School is made up of a Kindergarten (KG), a primary and junior school, all situated on one site in Abandze, a   rural village in the central region of Ghana. The school currently has around 65 children enrolled in both KG, the primary and secondary school. </p>`,
    photo: [
      { src: aban24_1 },
      { src: aban24_2 },
      { src: aban24_3 },
      { src: aban24_4 },
    ],
  },
  {
    id: "10",
    cart: "Message",
    image: paul24_3,
    title: "Providing Beds at Paul School for the Blind",
    date: " ",
    detail: `<p>Save Children in Africa in Need (SCAN) provided 25 beds and 27 mattresses to Paul School for the Blind</p><p>Paul school for the blind was founded in 1997, with the intention of providing education, empowerment, and to build the capacity of blind and low vision boys and girls to become useful and productive citizens in their communities and the country as a whole.</p><p>Its establishment was also aimed at extending this educational facility in the southern province where it would cater for the education of blind and low vision that will not need to go out of the region in search of educational facilities</p><p>It is co-educational and all 65 pupils (40 boys and 25 girls) board and lodge at the school as orphans. They go through 6 years of primary education and are then integrated into mainstream secondary and tertiary institutions however they continue to reside in the boarding home during this period as these children have been evicted from their community as they are deemed to be an economic burden. Upon graduation they then move into their own personal accommodation.</p><p>Since its inception, the school has directly trained and produced more than 35 blind and low vision graduates who are currently gainfully employed in various fields in society. The school continues to strive to provide quality education, care and protection for the pupils, and at the same time provides a conducive and learning environment for them.</p>`,
    photo: [
      { src: paul24_1 },
      { src: paul24_2 },
      { src: paul24_3 },
      { src: paul24_4 },
    ],
  },
  {
    id: "11",
    cart: "Message",
    image: summer24_4,
    title: "Summer Workshop in Nigeria in partnership with PLAI",
    date: " ",
    detail: `<p>In 2023, We fostered collaboration with partner organisations with similar objectives and extended our relationship with schools and colleges.   
    We established a link with Providence Legal Aid Initiative for Children (PLAI) in Nigeria</p><p>Scan worked with PLAI in sponsoring a Seminar for Children in Nigeria.  The Seminar examined the challenges or dangers encountered by children whose parents substitute their parental guide with all sorts of gadget such as TV subscriptions, Play station Consoles, etc. </p><p>They also discussed the advice that can be suggested to the parents on how to create quality time for their children.</p><p>PLAI delivers pre-employability training and employer lead job opportunities to young adults so that they can be economically self-sufficient and develop long life career plans and safer productive futures. They provide Training in Pre-Employability Courses, Construction Skills Certificate Scheme, Traineeships, Apprenticeships, SIA Security Training, Mental Health First Aid, etc.</p>`,
    photo: [
      { src: summer24_1 },
      { src: summer24_2 },
      { src: summer24_3 },
      { src: summer24_4 },
    ],
  },
  {
    id: "12",
    cart: "Message",
    image: sports24_5,
    title: "5 Nations Sports Day",
    date: " ",
    detail: `<p>Save children in Africa in Need (SCAN) hosted its first ever 5-Nations Sports and Family Fun Day event at the Lee Valley Athletics Outdoor Stadium, 61 Meridian Way, Edmonton, London N9 0AR on Saturday 29th July 2017 from 12.30 pm to 7.30 pm.  </p><p>The countries that participated in the sports event were Nigeria, Ghana, Zimbabwe, Uganda and Sierra Leone. Athletics and sports events on the day included the following track events for men & women (100m, 200m, 400m, 800m, 4x100m, 4x200m, 4x400m, Medley Relay, etc) and the following field events; Tug of War, Egg & Spoon, Sack race, Short put, Long Jump. There were also fun activities for children. </p> <p>This was a fantastic opportunity for young people to participate in athletics and six-a-side football. The focus of this event was to bring families and young people together, promote athletics and other sports and at the same time raise the awareness of the plight of desperately needy children in Africa.  We were able to actively involve the ethnic minority and the community in general and expand sporting activities in Enfield and the surrounding boroughs. There were athletes (including semi-professional athletes) from the 5 countries representing their respective countries. </p><p>It was a unique event which brought together local communities, with the view of allowing families and young people to interact with each other, share experiences, develop invaluable interconnected relationships and promote and expand athletics and other sports in the borough and at the same time, raising the awareness of the plight of needy children in Africa.</p>`,
    photo: [
      { src: sports24_1 },
      { src: sports24_2 },
      { src: sports24_3 },
      { src: sports24_4 },
      { src: sports24_5 },
    ],
  },
  {
    id: "13",
    cart: "Message",
    image: toilet24_7,
    title:
      "Providing Boys and Girls toilets and uniforms at Two Bridges Community School",
    date: " ",
    detail: "",
    photo: [
      { src: toilet24_1 },
      { src: toilet24_2 },
      { src: toilet24_3 },
      { src: toilet24_4 },
      { src: toilet24_5 },
      { src: toilet24_6 },
      { src: toilet24_7 },
    ],
  },
  {
    id: "14",
    cart: "Message",
    image: water24_3,
    title: "Providing water at Baptist High School in Nigeria",
    date: " ",
    detail: "",
    photo: [
      { src: water24_1 },
      { src: water24_2 },
      { src: water24_3 },
      { src: water24_4 },
      { src: water24_5 },
      { src: water24_6 },
    ],
  },

  {
    id: "15",
    cart: "Message",
    image: logoHeader,
    title:
      "Provided water well pumping machine and a water reservoir tank to St George Foundation Orphanage for Children in Sierra Leone",
    date: "February 2024 ",
    detail: ` <p>I trust this email finds you well. I want to express my sincere gratitude for your continued support in covering the electricity supply costs for our interim care center. Your generosity has made a significant difference in our ability to provide a safe and comfortable environment for those in need.   </p><p>To further enhance the facilities at the Children’s Orphanage SCAN provided a water well pumping machine and a water reservoir tank. This is to ensure that the Orphanage has sustainable water supply. The water well pumping machine and a water reservoir tank will not only benefit the residents of the Orphanage, but also the surrounding community, as access to clean and reliable water is a fundamental necessity.  </p> <p> The water well pumping machine and reservoir tank will allow the Orphanage to tap into an already clean underground water source, reducing our reliance on external water sources and ensuring a consistent water supply for the Orphanage and all its residents, leading to a more self-sustainable and cost-effective operation of the Orphanage.</p>`,
    // photo: [
    //   { src: water24_1 },
    //   { src: water24_2 },
    //   { src: water24_3 },
    //   { src: water24_4 },
    //   { src: water24_5 },
    //   { src: water24_6 },
    // ],
  },
  {
    id: "16",
    cart: "Message",
    image: logoHeader,
    title:
      "Provided Abandze Methodist Basic Primary School with 2 Projectors to enhance the quality of teaching and learning in the school.",
    date: "March 2024 ",
    detail: ``,
    // photo: [
    //   { src: water24_1 },
    //   { src: water24_2 },
    //   { src: water24_3 },
    //   { src: water24_4 },
    //   { src: water24_5 },
    //   { src: water24_6 },
    // ],
  },
  {
    id: "17",
    cart: "Message",
    image: logoHeader,
    title:
      "Providing Desks and Chairs to Tabernacle Primary School and Tower Hill Primary School ",
    date: "March 2024 ",
    detail: `<p>Both Tower Hill Primary School and Tabernacle Primary School were two of the of the best performing primary schools in Freetown. Due to funding and lack of resources, the quality of teaching and learning has declined.  Added to this, is the fact that there is a growing number of children in primary schools as a result of the free primary education introduced by the current government. This has resulted in overcrowded classrooms of more than 50 pupils, on average.  </p> <p>The phenomenon of overcrowded classroom as well as poor quality and inadequate furniture in the classroom due to increase in primary enrolment, has taken its toll on the two schools and the educational system in Sierra Leone as a whole.</p><p>The furniture will make a significant improvement in the learning environment of both Tower Hill and Tabernacle Primary Schools and this would further encourage the pupils and staff to perform well and improve the quality of education in Sierra Leone.</p>`,
    // photo: [
    //   { src: water24_1 },
    //   { src: water24_2 },
    //   { src: water24_3 },
    //   { src: water24_4 },
    //   { src: water24_5 },
    //   { src: water24_6 },
    // ],
  },
  {
    id: "18",
    cart: "Message",
    image: logoHeader,
    title:
      "Providing Beds and Classroom furniture and Teaching resources at MARKAZ HAAFIZHU IBUN HAJARI ORPHANAGE PRIMARY SCHOOL in Uganda ",
    date: "March 2024 ",
    detail: `<p>Markaz haafizhu Ibun Hajari Orphanage Primary School is located at Namaliga south, nyimbwa sub-county, bombo town council, luweero district in the central region of Uganda, 32 kilometers from Kampala.  </p> <p>Luweero district is boardered by nakasongola district to the north, kayunga district to the east, mukono district to the south, and nakaseke district to the west. The district headquarters at luweero are approximately 75 kilometers, by road, north of kampala, uganada's capital and largest city.</p><p>Luweero district was the site of a fierce insurgency by the rebel group National Resistance Army and brutal counter-insurgency by the government of Milton obote, known as Luweero war or the ''bush war'' that left many thousands of civilians dead during the early to mid- 1980s.</p> <p>The Orphanage Home was set up to take care of children, providing them with formal education and also teach skills like computer skills, carpentry skills, tailoring skills and welding skills to help solve the problem of unemployment and poverty in the community.</p> <p>The orphanage home caters for both boys and girls.  We have 200 children comprising of 180 boys, and 20 girls in the Orphanage. </p>`,
    // photo: [
    //   { src: water24_1 },
    //   { src: water24_2 },
    //   { src: water24_3 },
    //   { src: water24_4 },
    //   { src: water24_5 },
    //   { src: water24_6 },
    // ],
  },
];

// export const our_projects = shuffle(events);

export const our_projects = events.sort((a, b) => Number(b.id) - Number(a.id));
