import React, { useState, useEffect } from "react";
import "./about.css";
import { ImageSwiper } from "../../components";
import sanityClient from "../../client";

const AboutUs = () => {
  const [aboutUsContent, setAboutUsContent] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"] {
          headerTitle,
          description,
  }`
      )
      .then((data) => setAboutUsContent(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      <section className="aboutUs">
        <div className="container">
          <div className="content">
            <header class="header">
              {/* <h1>{aboutUsContent?.headerTitle}</h1> */}
              <h1>Who We Are</h1>
            </header>
            <div className="txtContent">
              <article>
                {/* <p>{aboutUsContent?.description}</p> */}
                <p>
                  Save Children in Africa in Need (SCAN) is a Registered
                  Charity, registration number 1104469, in England and Wales. It
                  is run by a group of caring professionals who are committed to
                  using their time, experience and resources to alleviate the
                  suffering and hardship of children in Africa.
                </p>
                <p>
                  The organisation was formed in April 2002 and it was formerly
                  called “Save African Children”. On Sunday 12th January 2003, a
                  Special Resolution was passed to alter the name of the
                  organization to “Save Children in Africa in Need”. This is to
                  better reflect the activities and the reason for the formation
                  of the organization. The organization was formed to help
                  children in Africa and who are in need. SCAN became a fully
                  Registered Charity on 21st June 2004.
                </p>
                <p>
                  SCAN is an effective grass root organisation with ongoing
                  projects supporting education, health and social initiatives
                  in rural villages in four African countries (Ghana, Sierra
                  Leone, Nigeria, Uganda and Zimbabwe).
                </p>
                <p>
                  SCAN wishes to bring new life and hope to such children, by
                  providing them with the basic necessities of life to improve
                  their chances of surviving childhood. Children are the future
                  leaders and the charity hopes to help in moulding their lives,
                  thus building a good foundation for the future.
                </p>
              </article>
              <article>
                <h3>Our vision for Africa</h3>
                <p>
                  We envisage that children in Africa will be the leaders of
                  tomorrow and as such endeavour to provide them with a better
                  quality of life and all the necessary facilities that will
                  bring hope and new life and develop them for the future.
                </p>
                <p>
                  A seven year-old girl, whose hands were amputated during the
                  war in Sierra Leone, asked a Methodist Minister on his visit
                  to the hospital where she was admitted; “Please sir, when will
                  my hands grow again?” He could not bring himself to say
                  “Never”. He prayed with her briefly and departed, as he was
                  unable to say “Never”.
                </p>
              </article>
              <article>
                <h3>Our Mission</h3>
                <p>
                  Relief of poverty of children in Africa from the effects of
                  war, disease and disability, by providing or assisting in the
                  provision of equipment, facilities and services as will
                  relieve their needs.
                </p>
              </article>
              <article>
                <h2>Our Strategic Goals (Organisational Objectives)</h2>
                <ul>
                  <li>
                    To provide the right environment for students and other
                    participants (individuals, businesses, churches, groups,
                    etc) in the U.K. to directly contribute towards charities,
                    and to enable them to help address the problems of targeted
                    children in Africa.
                  </li>
                  <li>
                    To provide intercultural learning experiences that bring
                    global cultures together by helping students connect,
                    thereby transforming the lives of participants through our
                    Schools Exchange Programme.{" "}
                  </li>{" "}
                  <li>
                    To provide hands-on project development and implementation
                    experience to participants (schools, churches, businesses,
                    individuals, etc) through the ‘Schools Awareness Programme’.
                  </li>
                  <li>
                    To raise funds for the procurement and distribution of
                    necessary project equipment e.g. pump heads for water
                    supply, computers for schools, laboratory equipment, books,
                    teachers, clothing, etc., which are difficult to source
                    locally.
                  </li>
                  <li>
                    To up-skill our target group in Africa through the provision
                    of training for employment through our ‘Skills for Life
                    Programme’.{" "}
                  </li>
                  <li>
                    To establish educational sponsorship for children in need in
                    Africa.
                  </li>
                </ul>
              </article>
              <article>
                <h2>Our Belief:</h2>
                <p>
                  Children should be loved and cared for and that this will be
                  reflected in their later lives when they become the mothers
                  and fathers and leaders of tomorrow.
                </p>
                <p>
                  Children matter and we acknowledge that every individual or
                  groups who share in our activities help to shape and mould the
                  life and purpose of children in Africa.
                </p>
                <p>
                  Children everywhere should be entitled to the same facilities
                  and resources such as health care, education and the basic
                  necessities of food, clothing and shelter, irrespective of
                  their location, race, gender, religion or culture so as to
                  better prepare them for their future duties and
                  responsibilities as adult citizens of their respective
                  countries.
                </p>
                <p>
                  In terms of rights, children with disabilities are no
                  different from children without disabilities. Facilities and
                  resources should be provided so that they can live normal
                  lives.
                </p>
                <p>
                  Children growing up as unloved and unwanted youngsters and who
                  are denied their right to be loved and wanted may become
                  bitter and develop into unbalanced adults. They often are
                  unable to rear children of their own properly and more often
                  reproduce the cycle of deprivation.
                </p>
              </article>
              <article>
                <h2>Our Development Opportunities</h2>
                <p>
                  To help and encourage children with disabilities so that they
                  can maintain their independence and self-respect. This will
                  involve providing an environment characterised by care,
                  guidance and support facilities within which these children
                  are helped to grow as individuals.
                </p>
                <p>
                  To encourage children to develop their potential, contribute
                  to their upbringing and help to mould their lives. This will
                  be done through training and development and educational
                  programmes in their respective countries.
                </p>
                <p>
                  To ensure that our services and facilities are accessible to
                  every child in Africa regardless of their location, race,
                  gender, religion, or culture. This is further discussed in the
                  Equal Opportunity Policy of the organisation.
                </p>
                <p>
                  To provide and encourage the interaction and participation of
                  children in their various African countries. This will be in
                  the form of sporting activities, exhibitions and exchange
                  programmes.
                </p>
                <p>
                  To be aware of the expectations and perceptions of children
                  and to respond to them. We do recognise and value each child,
                  and acknowledge the dignity and uniqueness of every child.{" "}
                  <br />{" "}
                  <i>
                    “This is what we are about. We plant the seed that one day
                    will grow. We water seeds already planted, knowing that they
                    hold future promise. We lay foundations that will need
                    development… We are prophets of a future not our own.”
                  </i>{" "}
                  <br />
                  <i>
                    “A child’s wish to smile, to be normal… is well worth the
                    time, money and effort we put into making that wish come
                    true.”
                  </i>{" "}
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="imageSlide">
        <ImageSwiper />
      </section>
    </>
  );
};

export default AboutUs;
