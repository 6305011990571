import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay } from "swiper";

// import { Pagination, Navigation } from "swiper";
import {
  sd1,
  blind2,
  schAware1,
  schX2,
  abandze1,
  sd6,
  schAware4,
  blind4,
  sd7,
  schX4,
  abandze4,
} from "../../assets";

import "./swiper.css";

const ImageSwiper = () => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <div className="swipeWrapper">
        <Swiper
          slidesPerView={5}
          spaceBetween={50}
          loop={true}
          //   pagination={{
          //     clickable: true,
          //   }}
          breakpoints={{
            250: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          //   navigation={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 1800,
            disableOnInteraction: true,
          }}
          className="imgSwipe"
        >
          <div className="imgSlideContainer">
            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={sd1} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={blind2} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={schAware1} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={abandze1} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={blind4} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={sd6} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={sd7} alt="" className="slide__image" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={schX4} alt="" className="slide__image" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={sd6} alt="" className="slide__image" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={abandze4} alt="" className="slide__image" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="imgSlide">
              <div className="imageHolder">
                <img src={schAware4} alt="" className="slide__image" />
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default ImageSwiper;
